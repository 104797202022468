// import { updateGridRows } from "../Api";
import { ALL_MEDIA_CHANNEL_KEYS } from "../containers/CategoryDataUtils";
import { spendToImpressions } from "./media";
import {
  GroupAggregationType,
  optimizationInfinity,
} from "./scenarioDataUtils";

const contributionRecalculationKeys = ["lower_spend", "upper_spend"];

function getFieldAggregationType(field) {
  return GroupAggregationType.Nothing;
}

const channelKeys = ALL_MEDIA_CHANNEL_KEYS;

function getFieldConstraints(field) {
  switch (field) {
    // TODO bring back constraints validation
    // case "lower_spend_pct":
    //   return {
    //     isValueValid: (v, { data, stringValue }) => {
    //       const lower = (v / 100) * data.starting_spend + data.starting_spend;
    //       const upper = data.upper_spend;
    //       // console.log("isValueValid", field, lower, upper, stringValue, data);
    //       return stringValue === "" || (0 <= lower && lower <= upper);
    //     },
    //     valueConstraintsText: "Must be between -100% and Upper Spend %",
    //   };
    // case "lower_spend":
    //   return {
    //     isValueValid: (v, { data, stringValue }) => {
    //       const lower = v || 0;
    //       const upper =
    //         data.upper_spend != null ? data.upper_spend : optimizationInfinity;
    //       // console.log("isValueValid", field, lower, upper, stringValue, data);
    //       return stringValue === "" || (0 <= lower && lower <= upper);
    //     },
    //     valueConstraintsText: "Must be between zero and Upper Spend",
    //   };
    // case "lower_impressions":
    //   return {
    //     isValueValid: (v, { data, stringValue }) => {
    //       const lower = v || 0;
    //       const upper = spendToImpressions(
    //         data.upper_spend != null ? data.upper_spend : optimizationInfinity,
    //         data.cpm
    //       );
    //       // console.log("isValueValid", field, lower, upper, stringValue, data);
    //       return stringValue === "" || (0 <= lower && lower <= upper);
    //     },
    //     valueConstraintsText: "Must be between zero and Upper Impressions",
    //   };
    // case "upper_spend_pct":
    //   return {
    //     isValueValid: (v, { data, stringValue }) => {
    //       const lower = data.lower_spend;
    //       const upper = (v / 100) * data.starting_spend + data.starting_spend;
    //       // console.log("isValueValid", field, lower, upper, stringValue, data);
    //       return stringValue === "" || lower <= upper;
    //     },
    //     valueConstraintsText: "Must be more than Lower Spend %",
    //   };
    // case "upper_spend":
    //   return {
    //     isValueValid: (v, { data, stringValue }) => {
    //       const lower = data.lower_spend || 0;
    //       const upper = v != null ? v : optimizationInfinity;
    //       // console.log("isValueValid", field, lower, upper, stringValue, data);
    //       return stringValue === "" || lower <= upper;
    //     },
    //     valueConstraintsText: "Must be more than Lower Spend",
    //   };
    // case "upper_impressions":
    //   return {
    //     isValueValid: (v, { data, stringValue }) => {
    //       const lower = data.lower_spend || 0;
    //       const upper = spendToImpressions(
    //         v != null ? v : optimizationInfinity,
    //         data.cpm
    //       );
    //       // console.log("isValueValid", field, lower, upper, stringValue, data);
    //       return stringValue === "" || lower <= upper;
    //     },
    //     valueConstraintsText: "Must be more than Lower Impressions",
    //   };
    default:
      return {
        isValueValid: () => true,
        valueConstraintsText: "",
      };
  }
}

/**
 * Prepare the item to be persisted.
 *
 * @param {Object} item The item to prepare.
 * @param {Object} user_id The current user ID.
 * @param {Object} scenario_id The current scenario ID.
 * @returns a new object which is safe for persisting in the backend.
 */
function prepareItemForBackend(item, user_id, scenario_id) {
  return {
    ...item,
    user_id,
    scenario_id,
  };
}

// /**
//  * Save the item in the database.
//  *
//  * @param {Object} item An item which was prepared by `prepareItemForBackend`.
//  * @see {@link prepareItemForBackend}
//  */
// async function persistItemHandler(item) {
//   const response = await updateGridRows("constraints", [item]);
//   console.log("persistItemHandler CONSTRAINTS", item, response);
// }

// /**
//  * Save the items in the database.
//  *
//  * @param {Object} item List of items which were prepared by `prepareItemForBackend`.
//  * @see {@link prepareItemForBackend}
//  */
// async function persistManyHandler(items) {
//   const response = await updateGridRows("constraints", items);
//   // console.log("persistManyHandler CONSTRAINTS", items, response);
//   // TODO handel errors
// }

/**
 * Modify the given item with side effects of the edit.
 *
 * @param {Object} item The item to modify
 * @param {Object[]} modifiedFields The fields that were modified
 * @returns the field that were modified (including those in the input)
 */
function runSideEffectsOnItem(item, modifiedFields, scenarioAspiration) {
  return [...modifiedFields];
}

/**
 * Removes invalid values
 *
 */
function validateUpdateMap(updateMap, target, item) {
  const validatedUpdateMap = { ...updateMap };
  const validationErrors = [];
  Object.entries(updateMap).forEach(([k, v]) => {
    const validationConstraint = getFieldConstraints(k);
    if (
      updateMap[k] != null &&
      !validationConstraint?.isValueValid?.(v, {
        data: item,
      })
    ) {
      const error = {
        target,
        field: k,
        value: v,
      };
      validationErrors.push(error);
      delete validatedUpdateMap[k];
    }
  });
  return { validatedUpdateMap, validationErrors };
}

export default {
  contributionRecalculationKeys,
  channelKeys,
  getFieldAggregationType,
  prepareItemForBackend,
  // persistItemHandler,
  // persistManyHandler,
  runSideEffectsOnItem,

  getFieldConstraints,
  validateUpdateMap,
};
