import { getDateFromISODate } from "../../constants";
import { getShortTarget } from "../../scenarioDataUtils/scenarioDataUtils";
import { v4 as uuidv4 } from "uuid";

class DatasourceError extends Error {
  constructor(message) {
    super(message);
    this.name = "DatasourceError";
  }
}

const periodDateFormat = new Intl.DateTimeFormat(undefined, {
  year: "numeric",
  month: "numeric",
  day: "numeric",
});

function getGroupPeriodString(period) {
  const startDate = getDateFromISODate(period.start);
  startDate.setUTCDate(startDate.getUTCDate() - 6);
  const endDate = getDateFromISODate(period.end);
  return periodDateFormat.formatRange(startDate, endDate);
}

async function getGroupRowParent(
  userId,
  scenarioId,
  shortTarget,
  channelKeys,
  getGroup
) {
  // console.log("getGroupRowParent", shortTarget);
  const result = await getGroup({
    userId,
    scenarioId,
    target: shortTarget,
  });
  if (result.data != null) return result;
  const nextShortTarget = getShortTarget(
    shortTarget,
    channelKeys.slice(0, Object.keys(shortTarget).length - 1)
  );
  if (nextShortTarget == null)
    throw new DatasourceError(
      "Could not find blank row parent that was guaranteed to exist"
    );
  return getGroupRowParent(
    userId,
    scenarioId,
    nextShortTarget,
    channelKeys,
    getGroup
  );
}

async function getGroupChildrenWrapper(
  shortTarget,
  channelKeys,
  userId,
  scenarioId,
  hierarchy,
  getGroupChildren,
  getGroup
) {
  const query = {
    userId,
    scenarioId,
    target: shortTarget,
    hierarchy,
  };
  // console.log("QUERY GROUP CHILDREN:", query);
  const { data, isLoading, isError, error } = await getGroupChildren(query);
  if (data.length > 0) {
    const rowData = data.map((d) => ({
      ...d,
      isBlankRow: false,
      shortTarget: getShortTarget(
        d,
        channelKeys.slice(0, Object.keys(shortTarget).length + 1)
      ),
    }));
    // console.log("ROW_DATA:", rowData);
    return { data: rowData, isLoading, isError, error };
  } else if (Object.keys(shortTarget).length === 0) {
    // got no data at top level
    throw new DatasourceError("Scenario does not have any data for this grid");
  } else {
    const {
      data: parent,
      isLoading,
      isError,
      error,
    } = await getGroupRowParent(
      userId,
      scenarioId,
      shortTarget,
      channelKeys,
      getGroup
    );
    const blankRowKeys = channelKeys.slice(
      0,
      Object.keys(shortTarget).length + 1
    );
    return {
      data: [
        {
          ...parent,
          ...(() => {
            let fillValue;
            return blankRowKeys.reduce((acc, k) => {
              fillValue = parent[k] ?? fillValue ?? "";
              return {
                ...acc,
                [k]: parent[k] ?? fillValue,
              };
            }, {});
          })(),
          isBlankRow: true,
          shortTarget: blankRowKeys.reduce(
            (acc, k) => ({
              ...acc,
              [k]: shortTarget[k] ?? null,
            }),
            {}
          ),
        },
      ],
      isLoading,
      isError,
      error,
    };
  }
}

function wrapWithUnsubscribe(trigger) {
  return async (...args) => {
    const request = trigger(...args);
    request.unsubscribe();
    return await request;
  };
}

export function createServerSideDatasource(
  userId,
  scenarioId,
  hierarchy,
  getGroupChildren,
  getGroup,
  getItems,
  onDataRendered
) {
  return {
    getRows: async (params) => {
      const channelKeysOLD = params.request.rowGroupCols.map(
        (col) => col.field
      );
      const channelKeys = hierarchy;
      // console.log("CHANNEL_KEYS:", channelKeys);
      const groupKeys = params.request.groupKeys;
      // console.log("GROUP_KEYS:", groupKeys);
      // If group row
      if (channelKeys.length > groupKeys.length) {
        // Get group children or group row
        const shortTarget = params.parentNode.data?.shortTarget ?? {};
        let totalRow = null;
        if (Object.keys(shortTarget).length === 0) {
          // Get the total row if the top level is requested
          // console.log({ hierarchy });
          const response = await wrapWithUnsubscribe(getGroup)({
            userId,
            scenarioId,
            target: shortTarget,
            hierarchy, // only needed for media to get constraint
            randomId: uuidv4(), // something else has a subscription and it is causing an issue here
          });
          if (response.isSuccess) {
            totalRow = {
              ...response.data,
              isTotalRow: true,
              [channelKeys[0]]: "Total",
              shortTarget: {},
            };
          } else if (response.isError) {
            console.error(
              `Error loading total row. ${response.error.status}: ${response.error.data.message}`
            );
          }
        }

        let groupChildrenResponse;
        try {
          groupChildrenResponse = await getGroupChildrenWrapper(
            shortTarget,
            channelKeys,
            userId,
            scenarioId,
            hierarchy,
            wrapWithUnsubscribe(getGroupChildren),
            wrapWithUnsubscribe(getGroup)
          );
        } catch (e) {
          if (e instanceof DatasourceError) {
            console.error(e);
            params.fail();
            return;
          }
        }

        const { data: childrenGroups, isError } = groupChildrenResponse;

        // If success
        if (!isError) {
          // Format period
          const rowData = childrenGroups.concat(totalRow ?? []).map((d) => ({
            ...d,
            period: getGroupPeriodString(d.period),
          }));
          // Set rowData and rowCount
          params.success({
            // Sometimes the grid sends request in reverse order.
            // If both are fulfilled with the full amount, the grid with bug out.
            // This solves it with still not having to do requests for every 20 rows. Same goes for items
            rowData: rowData.slice(params.request.startRow),
            rowCount: rowData.length,
          });
          onDataRendered(params);
          // params.columnApi.autoSizeColumn("ag-Grid-AutoColumn");
          // params.api.sizeColumnsToFit();
          // If error
        } else {
          // Tell grid about it
          params.fail();
        }
      } else {
        const query = {
          userId,
          scenarioId,
          target:
            // params.parentNode.data.isBlankRow ?
            params.parentNode.data.shortTarget,
          // getTarget(groupKeys, channelKeys)
        };
        // console.log("QUERY ITEMS:", query);
        const { data, isLoading, isError, error } = await wrapWithUnsubscribe(
          getItems
        )(query);
        // console.log("ROW_DATA:", data);
        if (!isError) {
          params.success({
            rowData: data.slice(params.request.startRow),
            rowCount: data.length,
          });
          onDataRendered(params);
          // params.columnApi.autoSizeColumn("ag-Grid-AutoColumn");
          // params.api.sizeColumnsToFit();
        } else {
          params.fail();
        }
      }
    },
  };
}
