import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { getISODate } from "../../constants";

export function convertDate(date) {
  return date == null || isNaN(date) ? null : getISODate(date);
}

function PeriodSelect({
  label,
  value,
  onChange,
  defaultDate,
  minDate,
  maxDate,
}) {
  const [anchorEl, setAnchorEl] = useState();
  const intl = useIntl();
  const dateOutsideOfRange = useCallback(
    (date) => date < minDate || date > maxDate,
    [minDate, maxDate]
  );

  const dateOnDay = useCallback(
    (date) => date.getDay() !== defaultDate.getDay(),
    [defaultDate]
  );

  const shouldDisableDate = useCallback(
    (date) => {
      if (defaultDate == null || minDate == null || maxDate == null) {
        return false;
      }
      // range error message should have priority over day of the week error message
      return !dateOutsideOfRange(date, minDate, maxDate) && dateOnDay(date);
    },
    [defaultDate, minDate, maxDate]
  );

  const [error, setError] = useState();
  const errorMessage = useMemo(() => {
    switch (error) {
      case "minDate":
        return `Earliest is ${intl.formatDate(minDate)}`;
      case "maxDate":
        return `Latest is ${intl.formatDate(maxDate)}`;
      case "shouldDisableDate":
        return `Must be on a ${intl.formatDate(defaultDate, {
          weekday: "long",
        })}`;
      case "invalidDate":
        return "Invalid date";
      default:
        return " ";
    }
  }, [error]);

  const mountScrollTop = document.documentElement.scrollTop;

  // fix scroll from popper flip disabled
  useEffect(() => {
    const handleScroll = () =>
      document.documentElement.scrollTo({ top: mountScrollTop });

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={label}
        slotProps={{
          textField: {
            variant: "standard",
            InputLabelProps: { shrink: true },
            InputProps: { ref: setAnchorEl },
            helperText: errorMessage,
          },
          // fix date picker on top of text field on small screens
          // fix bug with year picker selection closing picker without change
          popper: {
            anchorEl: anchorEl,
            modifiers: [{ name: "flip", enabled: false }],
          },
        }}
        value={value}
        onChange={onChange}
        shouldDisableDate={shouldDisableDate}
        minDate={minDate}
        maxDate={maxDate}
        onError={(error) => setError(error)}
      />
    </LocalizationProvider>
  );
}

export default PeriodSelect;
