import { color as am4Color } from "@amcharts/amcharts4/.internal/core/utils/Color";
import {
  isMediaKey,
  isSalesKey,
  mediaKeyPrefix,
  salesKeyPrefix,
} from "./utils/targetUtils";

export const resultName = "Return";
export const startingName = "Initial";
export const finalName = "Projected";

export const AspirationType = {
  Simulate: "simulate",
  Optimize: "optimize",
  Grow: "grow",
};

export function isValidAspiration(aspiration) {
  return Object.values(AspirationType).includes(aspiration);
}

// scenario that uses constraints and has the backend run the results
export function isOptimizationAspiration(aspiration) {
  return [AspirationType.Optimize, AspirationType.Grow].includes(aspiration);
}

export function getAspirationDisplayName(aspiration) {
  const displayNameMap = {
    [AspirationType.Simulate]: "Simulate",
    [AspirationType.Optimize]: "Optimize",
    [AspirationType.Grow]: "Grow",
  };
  return displayNameMap[aspiration] || "";
}

export const colors = {
  grey: "#7F7F7F", // black, lightest shade
  darkGrey: "#636568", // dark grey
  blue: "#54A3CD", // cool blue
  simulationBlue: "#55c5de",
  red: "#C31F46", // cool red
  green: "#ACC337", // green
  lightGreen: "#b2c843",
  yellow: "#f3ce3b", // yellow
  brightRed: "#ff1b33", // i4i red
  brightBlue: "#28abe2", // i4i blue
  orange: "#fe9f47", // optimize orange
  purple: "#A85BA4",
};

function getStartingColor(finalColor) {
  return am4Color(finalColor).lighten(0.4).hex;
}

export function getColorBySeriesKey(seriesKey) {
  switch (seriesKey.toLowerCase()) {
    case "up":
      return colors.purple;
    case "down":
      return colors.red;
    case "starting_spend":
      return getStartingColor(colors.simulationBlue);
    case "final_spend":
      return colors.simulationBlue;
    case "starting_contribution":
      return getStartingColor(colors.orange);
    case "final_contribution":
      return colors.orange;
    case "starting_profit":
      return getStartingColor(colors.yellow);
    case "final_profit":
      return colors.yellow;
    case "starting_roi":
      return getStartingColor(colors.lightGreen);
    case "final_roi":
      return colors.lightGreen;
    case "starting_sales":
      return getStartingColor(colors.darkGrey);
    case "final_sales":
      return colors.darkGrey;
    default:
      return colors[seriesKey];
  }
}

export function getChannelDisplayName(key) {
  if (isMediaKey(key)) {
    return key.replace(mediaKeyPrefix, "Media Channel ");
  }
  if (isSalesKey(key)) {
    return key.replace(salesKeyPrefix, "Sales Channel ");
  }
  return key;
}

export const zeroTimeString = "T00:00:00";

// TODO move these to a better place
export function dateToISODateString(date) {
  return date.toISOString().split("T")[0];
}

export function getISODate(date) {
  return dateToISODateString(date) + zeroTimeString;
}

export function getDateFromISODate(isoDate) {
  return new Date(isoDate + zeroTimeString);
}

export function getStartOfWeekDate(endOfWeek) {
  const result = new Date(endOfWeek);
  result.setDate(result.getDate() - 6);
  return result;
}

export function getEndOfWeekDate(startOfWeek) {
  const result = new Date(startOfWeek);
  result.setDate(result.getDate() + 6);
  return result;
}

export function getMaybeNullDate(dateString) {
  if (dateString == null) return null;
  return new Date(dateString);
}

export function parseDecimalInput(v, numDecimals) {
  // keep only digits before first period, the first period, and the first digit after the first period
  const isNegative = v.replaceAll(/[^\-\d]/g, "").charAt(0) === "-";
  let stringValue = v.replaceAll(/[^.,\d]/g, "");
  const decimalSeparatorPosition = stringValue.indexOf(".");
  if (numDecimals >= 0 && decimalSeparatorPosition >= 0) {
    stringValue =
      stringValue.substring(0, decimalSeparatorPosition + 1) +
      stringValue
        .substring(decimalSeparatorPosition)
        .replaceAll(/[^\d]/g, "")
        .substring(0, numDecimals);
  }
  return {
    string: (isNegative ? "-" : "") + stringValue,
    float:
      (isNegative ? -1 : 1) *
      (parseFloat(stringValue.replaceAll(",", "")) || 0),
  };
}

/**
 * Find the cursor selection after formatting. Strings use the replace rules
 *
 * @param {string} beforeCursorString The formatting string before the cursor.
 * @param {string} newString The new formatted string.
 * @returns
 */
export function calculateNewSelection(beforeCursorString, newString) {
  return beforeCursorString.split("").reduce((acc, beforeChar, i) => {
    if (beforeChar === newString[i]) return acc + 1;
    return acc;
  }, 0);
}
export const oldDefaultExportFormats = [
  {
    format: "svg",
    label: "Export SVG",
  },
  {
    format: "png",
    label: "Export PNG",
  },
  {
    format: "csv",
    label: "Export CSV",
  },
];

export const defaultExportFormats = [
  {
    format: "png",
    label: "Export PNG",
  },
  {
    format: "jpg",
    label: "Export JPEG",
  },
  {
    format: "xlsx",
    label: "Export Excel",
  },
  {
    format: "csv",
    label: "Export CSV",
  },
];
