import { Box } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { selectIsScenarioReady } from "../../app/scenarioSlice";
import LoadingSection, {
  LoadingType,
} from "../../components/LoadingSection/LoadingSection";
import OptimizationLevels from "../OptimizationLevel/OptimizationLevels";

function GranularityControls() {
  const isScenarioReady = useSelector(selectIsScenarioReady);

  return (
    <LoadingSection isLoading={!isScenarioReady} type={LoadingType.Hide}>
      <Box sx={{ mx: 4, my: 1 }}>
        <OptimizationLevels sx={{ py: 1 }} />
      </Box>
    </LoadingSection>
  );
}

export default GranularityControls;
