// import { updateGridRows } from "../Api";
import {
  finalName,
  isOptimizationAspiration,
  startingName,
} from "../constants";
import {
  ALL_CHANNEL_KEYS,
  ALL_MEDIA_CHANNEL_KEYS,
} from "../containers/CategoryDataUtils";
import { categoryVectorContains2 } from "../utils/channelUtils";
import { objectFilter } from "../utils/objectUtil";
import {
  executeUpdateOnItem,
  getCVFromTarget,
  getItemGroupMatcher,
  getItemMatcher,
  getTargetFromItem,
  GroupAggregationType,
  mergeItems,
  optimizationInfinity,
  ScenarioDataKeys,
} from "./scenarioDataUtils";

import { scenarioDataHelpers } from "./scenarioDataUtils";

const contributionRecalculationKeys = [
  "starting_spend",
  "final_spend",
  "starting_impressions",
  "final_impressions",
  "cpm",
  "half_life",
  "half_saturation_impressions",
  "saturation_shape",
  "coefficient",

  // optimize only
  "lower_spend",
  "upper_spend",
  "lower_spend_pct",
  "upper_spend_pct",
  "lower_impressions",
  "upper_impressions",
];

const fieldsOrder = [
  "lower_spend",
  "lower_spend_pct",
  "lower_impressions",
  "starting_spend",
  "starting_impressions",
  "upper_spend",
  "upper_spend_pct",
  "upper_impressions",
  "final_spend",
  "final_impressions",
  "cpm",
  "half_life",
  "half_saturation_impressions",
  "saturation_shape",
  // "coefficient",
];

const channelKeys = ALL_MEDIA_CHANNEL_KEYS;

function getFieldAggregationType(field) {
  switch (field) {
    case "starting_spend":
    case "final_spend":
    case "starting_impressions":
    case "final_impressions":
      return GroupAggregationType.Sum;
    case "cpm":
      return GroupAggregationType.CpmAverage;
    case "half_life":
    case "half_saturation_impressions":
    case "saturation_shape":
      // case "coefficient":
      return GroupAggregationType.Copy;
    case "lower_spend":
    case "upper_spend":
    case "lower_impressions":
    case "upper_impressions":
      return GroupAggregationType.Nothing;
    default:
      return GroupAggregationType.Copy;
  }
}

function getGroupItem(group, state, target) {
  const mediaGroupItem = objectFilter(
    group.reduce((acc, item, index) => {
      for (const f in item) {
        const v = item[f];
        if (ALL_CHANNEL_KEYS.includes(f)) {
          acc[f] = v;
          continue;
        }
        if (constraintFields.includes(f)) {
          // handled after
          continue;
        }
        if (getFieldAggregationType(f) === GroupAggregationType.Sum) {
          acc[f] = (acc[f] || 0) + v;
        }
        if (getFieldAggregationType(f) === GroupAggregationType.Copy) {
          if (
            (typeof v === "number" && typeof acc[f] === "number") ||
            v == null
          ) {
            // average
            acc[f] = (acc[f] * index + (v || 0)) / (index + 1);
          } else {
            acc[f] = v;
          }
        }
        if (getFieldAggregationType(f) === GroupAggregationType.Nothing) {
          acc[f] = null;
        }
      }
      return acc;
    }, {}),
    ([f]) => [...ALL_CHANNEL_KEYS, ...fieldsOrder].includes(f)
  );

  const constraint = state[ScenarioDataKeys.Constraints].find(
    getItemGroupMatcher(target, ScenarioDataKeys.Constraints)
  );
  console.log(mediaGroupItem, constraint);

  const constraintsItem = {
    lower_spend: constraint?.lower_spend,
    upper_spend: constraint?.upper_spend,
  };

  if (constraintsItem.lower_spend == null) {
    constraintsItem.lower_spend = 0;
  }
  if (constraintsItem.upper_spend == null) {
    constraintsItem.upper_spend = optimizationInfinity;
  }
  constraintsItem.lower_spend_pct =
    (constraintsItem.lower_spend - mediaGroupItem.starting_spend) /
    mediaGroupItem.starting_spend;
  constraintsItem.lower_impressions = spendToImpressions(
    constraintsItem.lower_spend,
    mediaGroupItem.cpm
  );
  constraintsItem.upper_spend_pct =
    (constraintsItem.upper_spend - mediaGroupItem.starting_spend) /
    mediaGroupItem.starting_spend;
  constraintsItem.upper_impressions = spendToImpressions(
    constraintsItem.upper_spend,
    mediaGroupItem.cpm
  );

  return { ...mediaGroupItem, ...constraintsItem };
}

function getFieldDisplayName(field) {
  switch (field) {
    case "starting_spend":
      return startingName + " Spend";
    case "final_spend":
      return finalName + " Spend";
    case "starting_impressions":
      return startingName + " Impressions";
    case "final_impressions":
      return finalName + " Impressions";
    case "cpm":
      return "CPM";
    case "half_life":
      return "Half-Life";
    case "half_saturation_impressions":
      return "Half Saturation Impressions";
    case "saturation_shape":
      return "Saturation Shape";
    // case "coefficient":
    case "lower_spend_pct":
      return "Lower Spend %";
    case "upper_spend_pct":
      return "Upper Spend %";
    case "lower_spend":
      return "Lower Spend";
    case "upper_spend":
      return "Upper Spend";
    case "lower_impressions":
      return "Lower Impressions";
    case "upper_impressions":
      return "Upper Impressions";
    default:
      return null;
  }
}

export const spendToImpressions = (v, cpm) => (v / cpm) * 1000;
export const impressionsToSpend = (v, cpm) => (v * cpm) / 1000;

export const constraintFields = [
  "lower_spend_pct",
  "upper_spend_pct",
  "lower_spend",
  "upper_spend",
  "lower_impressions",
  "upper_impressions",
];

function getFieldConstraints(field) {
  switch (field) {
    case "starting_spend":
    case "final_spend":
    case "starting_impressions":
    case "final_impressions":
    case "half_life":
      return {
        isValueValid: (v) => v >= 0,
        valueConstraintsText: "Must be positive",
      };
    case "half_saturation_impressions":
    case "cpm":
      return {
        isValueValid: (v) => v > 0,
        valueConstraintsText: "Must be greater than zero",
      };
    case "saturation_shape":
      return {
        isValueValid: (v) => 0 < v && v <= 3,
        valueConstraintsText: "Must be between zero and three",
      };
    case "lower_spend_pct":
    case "lower_spend":
    case "lower_impressions":
    case "upper_spend_pct":
    case "upper_spend":
    case "upper_impressions":
      return scenarioDataHelpers[
        ScenarioDataKeys.Constraints
      ].getFieldConstraints(field);
    // case "coefficient":
    default:
      return {
        isValueValid: () => true,
        valueConstraintsText: "",
      };
  }
}

/**
 * Prepare the item to be persisted.
 *
 * @param {Object} item The item to prepare.
 * @param {Object} user_id The current user ID.
 * @param {Object} scenario_id The current scenario ID.
 * @returns a new object which is safe for persisting in the backend.
 */
function prepareItemForBackend(item, user_id, scenario_id) {
  return {
    ...item,
    user_id,
    scenario_id,
  };
}

// /**
//  * Save the items in the database.
//  *
//  * @param {Object} item List of items which were prepared by `prepareItemForBackend`.
//  * @see {@link prepareItemForBackend}
//  */
// async function persistManyHandler(items) {
//   const response = await updateGridRows("media", items);
//   // console.log("persistManyHandler MEDIA", items, response);
//   // TODO handel errors
// }

/**
 * Modify the given item with side effects of the edit.
 *
 * @param {Object} item The item to modify
 * @param {Object[]} modifiedFields The fields that were modified
 * @returns the field that were modified (including those in the input)
 */
function runSideEffectsOnItem(item, modifiedFields, scenarioAspiration) {
  // TODO refactor

  const getFieldWithPrefix = (prefix, field) => [prefix, field].join("_");
  const shouldRunFieldSideEffects = (field) =>
    modifiedFields.includes(field) && item[field] != null;

  const changes = {};
  const getValueWithChange = (field) => changes[field] || item[field];

  const _spendToImpressions = (v) =>
    spendToImpressions(v, getValueWithChange("cpm"));
  const _impressionsToSpend = (v) =>
    impressionsToSpend(v, getValueWithChange("cpm"));

  // cpm
  if (shouldRunFieldSideEffects("cpm")) {
    if (getValueWithChange("lower_spend") != null) {
      changes.lower_impressions = _spendToImpressions(
        getValueWithChange("lower_spend")
      );
    }
    if (getValueWithChange("upper_spend") != null) {
      changes.upper_impressions =
        getValueWithChange("upper_spend") === optimizationInfinity
          ? optimizationInfinity
          : _spendToImpressions(getValueWithChange("upper_spend"));
    }
    if (getValueWithChange("starting_spend") != null) {
      changes.starting_impressions = _spendToImpressions(
        getValueWithChange("starting_spend")
      );
    }
    if (getValueWithChange("final_spend") != null) {
      changes.final_impressions = _spendToImpressions(
        getValueWithChange("final_spend")
      );
    }
  }

  // spend / impressions with priority
  ["starting", "final", "lower", "upper"].forEach((prefix) => {
    if (getValueWithChange("cpm") != null) {
      const [spend, impressions] = ["spend", "impressions"].map((f) =>
        getFieldWithPrefix(prefix, f)
      );
      if (shouldRunFieldSideEffects(spend)) {
        changes[impressions] = _spendToImpressions(getValueWithChange(spend));
      } else if (shouldRunFieldSideEffects(impressions)) {
        changes[spend] = _impressionsToSpend(getValueWithChange(impressions));
      }
    }
  });

  Object.entries(changes).forEach(([field, value]) => {
    item[field] = value;
  });

  return [...modifiedFields, ...Object.keys(changes)];
}

function runSpecialGroupUpdates(
  state,
  { target, updateMap, scenarioAspiration }
) {
  // Update group constraint
  const constraintUpdateMap = {};
  ["lower_spend", "upper_spend"].forEach((spendBoundField) => {
    if (Object.keys(updateMap).includes(spendBoundField)) {
      constraintUpdateMap[spendBoundField] = updateMap[spendBoundField];
    }
  });
  if (Object.keys(constraintUpdateMap).length === 0)
    return {
      state,
      updateMap,
      changedFields: [],
    };

  const targetMatcher = getItemMatcher(target, ScenarioDataKeys.Constraints);
  const constraintCopy = {
    ...state[ScenarioDataKeys.Constraints].find(targetMatcher),
  };
  // console.log("runSpecialGroupUpdates", constraintCopy, state[ScenarioDataKeys.Constraints], target, constraintUpdateMap, scenarioAspiration);

  executeUpdateOnItem({
    sdKey: ScenarioDataKeys.Constraints,
    item: constraintCopy,
    updateMap: constraintUpdateMap,
    scenarioAspiration,
  });

  const newState = {
    ...state,
    [ScenarioDataKeys.Constraints]: state[ScenarioDataKeys.Constraints].map(
      (item) => (targetMatcher(item) ? constraintCopy : item)
    ),
    groupDbUpdates: {
      ...state.groupDbUpdates,
      [ScenarioDataKeys.Constraints]: {
        group:
          state.groupDbUpdates[ScenarioDataKeys.Constraints].concat(
            constraintCopy
          ),
        changedFields: [
          ...state.groupDbUpdates[ScenarioDataKeys.Constraints],
          ...Object.keys(constraintUpdateMap),
        ],
      },
    },
  };

  const filteredUpdateMap = Object.fromEntries(
    Object.entries(updateMap).filter(
      ([f]) => !["lower_spend", "upper_spend"].includes(f)
    )
  );
  return {
    state: newState,
    updateMap: filteredUpdateMap,
    changedFields: Object.keys(constraintUpdateMap),
  };
}

function totalUpdateSideEffects(
  state,
  oldState,
  { target, modifiedFields, scenarioAspiration }
) {
  if (
    !modifiedFields.includes("starting_spend") ||
    !isOptimizationAspiration(scenarioAspiration)
  )
    return;
  // console.log("STARTING SPEND CHANGE", state, oldState, target);
  // assumes that media and constraints have the same channel keys
  const targetCV = getCVFromTarget(target, channelKeys);
  const relatedConstraints = state[ScenarioDataKeys.Constraints].filter(
    (constraint) => {
      const constraintCV = getCVFromTarget(constraint, channelKeys);
      return categoryVectorContains2(constraintCV, targetCV);
    }
  );
  // console.log(relatedConstraints, targetCV);
  const changedConstraints = [];
  relatedConstraints.forEach((constraint) => {
    if (
      constraint.lower_spend === 0 &&
      constraint.upper_spend === optimizationInfinity
    )
      return;
    const mediaMatcher = getItemGroupMatcher(
      constraint,
      ScenarioDataKeys.Constraints
    );
    const getTotalSpend = (s) =>
      s[ScenarioDataKeys.Media]
        .filter(mediaMatcher)
        .reduce((acc, { starting_spend }) => acc + starting_spend, 0);
    const oldSpendBase = getTotalSpend(oldState);
    const newSpendBase = getTotalSpend(state);

    if (oldSpendBase < 0.5) {
      if (constraint.lower_spend || constraint.upper_spend) {
        // set to 20%
        changedConstraints.push({
          ...constraint,
          ...(constraint.lower_spend !== 0 && {
            lower_spend: newSpendBase * 0.8,
          }),
          ...(constraint.upper_spend !== optimizationInfinity && {
            upper_spend: newSpendBase * 1.2,
          }),
        });
      }
      return;
    }

    changedConstraints.push({
      ...constraint,
      lower_spend: (constraint.lower_spend / oldSpendBase) * newSpendBase,
      ...(constraint.upper_spend !== optimizationInfinity && {
        upper_spend: (constraint.upper_spend / oldSpendBase) * newSpendBase,
      }),
    });
  });

  const newState = {
    ...state,
    [ScenarioDataKeys.Constraints]: mergeItems(
      ScenarioDataKeys.Constraints,
      state[ScenarioDataKeys.Constraints],
      changedConstraints
    ),
    groupDbUpdates: {
      ...state.groupDbUpdates,
      [ScenarioDataKeys.Constraints]: {
        group:
          state.groupDbUpdates[ScenarioDataKeys.Constraints].concat(
            changedConstraints
          ),
        changedFields: [
          ...state.groupDbUpdates[ScenarioDataKeys.Constraints],
          "lower_spend",
          "upper_spend",
        ],
      },
    },
  };

  // console.log("changedConstraints", changedConstraints, newState);
  console.log("changedConstraints", changedConstraints);

  return newState;
}

function processImportItem(item, oldMatch, scenarioAspiration) {
  const getValueWithChange = (field) => item[field] || oldMatch[field];
  const processedItem = { ...item };
  // convert bounds spend % to spend
  const spendBase = getValueWithChange("starting_spend");
  ["lower", "upper"].forEach((prefix) => {
    const [spendPct, spend] = ["spend_pct", "spend"].map((f) =>
      [prefix, f].join("_")
    );
    if (item[spendPct] != null) {
      processedItem[spend] = (item[spendPct] / 100 + 1) * spendBase;
      delete processedItem[spendPct];
    }
  });

  const validationErrors = [];
  Object.entries(processedItem).forEach(([k, v]) => {
    const constraint = getFieldConstraints(k);
    if (
      processedItem[k] != null &&
      !constraint.isValueValid(v, {
        data: { ...oldMatch, ...processedItem },
      })
    ) {
      const error = {
        target: getTargetFromItem(processedItem, channelKeys),
        field: k,
        value: v,
      };
      validationErrors.push(error);
      processedItem[k] = undefined;
    }
  });

  return { result: processedItem, validationErrors };
}

/**
 * Removes invalid values
 *
 */
function validateUpdateMap(updateMap, target, item) {
  const validatedUpdateMap = { ...updateMap };
  const validationErrors = [];
  Object.entries(updateMap).forEach(([k, v]) => {
    const validationConstraint = getFieldConstraints(k);
    if (
      updateMap[k] != null &&
      !validationConstraint?.isValueValid?.(v, {
        data: item,
      })
    ) {
      const error = {
        target,
        field: k,
        value: v,
      };
      validationErrors.push(error);
      delete validatedUpdateMap[k];
    }
  });
  return { validatedUpdateMap, validationErrors };
}

export default {
  contributionRecalculationKeys,
  channelKeys,
  getFieldAggregationType,
  prepareItemForBackend,
  // persistItemHandler,
  // persistManyHandler,
  runSideEffectsOnItem,
  totalUpdateSideEffects,

  processImportItem,
  runSpecialGroupUpdates,

  getFieldConstraints,
  getFieldDisplayName,
  fieldsOrder,

  getGroupItem,
  validateUpdateMap,
};
