export function buildGetFallbackTarget(mediaHierarchy) {
  return (currentTarget, targets) => {
    let candidates = targets;
    for (const currentChannel of mediaHierarchy) {
      const next = candidates.filter(
        (c) => c[currentChannel] === currentTarget[currentChannel]
      );
      // console.log(next);
      if (next.length === 0) break;
      candidates = next;
    }
    return candidates?.[0] || {};
  };
}
