import { mc } from "../utils/targetUtils";

export const MediaChannel = {
  One: "media_channel_1",
  Two: "media_channel_2",
  Three: "media_channel_3",
};

export const SalesChannel = {
  One: "sales_channel_1",
  Two: "sales_channel_2",
  Three: "sales_channel_3",
};

export const ALL_MEDIA_CHANNEL_KEYS = [
  MediaChannel.One,
  MediaChannel.Two,
  MediaChannel.Three,
];
export const ALL_SALES_CHANNEL_KEYS = [
  SalesChannel.One,
  SalesChannel.Two,
  SalesChannel.Three,
];

export const ALL_CHANNEL_KEYS = [
  ...ALL_MEDIA_CHANNEL_KEYS,
  ...ALL_SALES_CHANNEL_KEYS,
];

export const EXPANSION_CATEGORY_KEYS = ALL_MEDIA_CHANNEL_KEYS;
export const SELECTION_CATEGORY_KEYS = ALL_CHANNEL_KEYS;

export function categoryVectorToCategoryName(categoryVector) {
  return categoryVector.join(" ");
}

export function getNameFromTarget(t, channelKeys) {
  return categoryVectorToCategoryName(channelKeys.map((k) => t[k]));
}

// get the name of a row's data according to the expansion data
// pass in undefined expansionData to get at full expansion using all category keys
export function getCategoryName(d, expansionData) {
  function _getCategoryName(curDepth, expansionData) {
    const categoryKey =
      expansionData === undefined
        ? ALL_CHANNEL_KEYS[curDepth]
        : EXPANSION_CATEGORY_KEYS[curDepth];
    const category = d[categoryKey];
    if (expansionData === null) return [];
    if (expansionData === undefined && curDepth >= ALL_CHANNEL_KEYS.length)
      return [];
    return (category != null ? [category] : []).concat(
      _getCategoryName(
        curDepth + 1,
        category != null && expansionData !== undefined
          ? expansionData[category]
          : expansionData
      )
    );
  }
  return categoryVectorToCategoryName(_getCategoryName(0, expansionData));
}

export function getAggNodeCategoryVector(node, channelKeys = ALL_CHANNEL_KEYS) {
  function _getAggNodeCategoryVector(n, keyIndex) {
    if (n.parent === undefined) return [];
    if (!n.group) return _getAggNodeCategoryVector(n.parent, keyIndex);
    if (n.field !== channelKeys[keyIndex])
      return _getAggNodeCategoryVector(n, keyIndex - 1).concat(undefined);
    return _getAggNodeCategoryVector(n.parent, keyIndex - 1).concat(n.key);
  }
  const categoryIndex = channelKeys.findIndex((key) => key === node.field);
  return _getAggNodeCategoryVector(
    node,
    categoryIndex < 0 ? channelKeys.length - 1 : categoryIndex
  );
}

export function getCategoryVector(d, expansionData) {
  return ALL_CHANNEL_KEYS.reduce(
    (acc, key) => {
      if (expansionData !== undefined && acc.curExpansionData == null)
        return acc;
      return {
        curExpansionData: acc.curExpansionData?.[d[key]],
        result: acc.result.concat(d[key] || undefined),
      };
    },
    { curExpansionData: expansionData, result: [] }
  ).result;
}

export function isSelected(d, selectionData, categoryKeys) {
  let currentSelectionData = selectionData;
  for (const key of categoryKeys || SELECTION_CATEGORY_KEYS) {
    if (currentSelectionData.hasOwnProperty(null)) {
      currentSelectionData = currentSelectionData[null];
      continue;
    }
    currentSelectionData = currentSelectionData[d[key]];
    if (currentSelectionData == null) {
      return false;
    }
  }
  return true;
}

export function getSelectedResults(
  results,
  selectionData,
  categoryKeys = SELECTION_CATEGORY_KEYS
) {
  return results.filter((d) => isSelected(d, selectionData, categoryKeys));
}

export function getAllChildGroupNodes(node) {
  const allChildren = [node];
  (function _getAllChildGroupNodes(currentNode) {
    const children = currentNode.childrenAfterSort;
    if (children === undefined) return;
    allChildren.push(...children.filter((n) => n.group));
    children.forEach(_getAllChildGroupNodes);
  })(node);
  return allChildren;
}

export function getRelatedGroupRows(node) {
  function getRoot(currentNode, targetField) {
    if (currentNode.field === targetField || !currentNode.parent.parent)
      return currentNode;
    return getRoot(currentNode.parent, targetField);
  }
  const mediaRoot = getRoot(node, mc(1));
  return getAllChildGroupNodes(mediaRoot);
}

function getGroupNodes(api) {
  const groupNodes = [];
  api.forEachNode((node) => {
    if (node.group) {
      groupNodes.push(node);
    }
  });
  return groupNodes;
}

export function buildSelectionData(gridApi) {
  const nodes = getGroupNodes(gridApi);
  const selectedNodes = nodes.filter((d) => d.selected !== false);
  const data = {};
  let index = -1;

  // Add nulls until the selection tree reaches the maximum depth.
  function buildNullBranch(children, nextNodeDepth) {
    if (nextNodeDepth < SELECTION_CATEGORY_KEYS.length) {
      children[null] = buildNullBranch({}, nextNodeDepth + 1);
    }
    return children;
  }

  function buildChildren(node, nextNodeDepth) {
    let children = {};
    while (index++ < selectedNodes.length) {
      // loop an extra time for the below hack
      const nextNode = selectedNodes[index];
      if (index === selectedNodes.length || node.level >= nextNode.level) {
        // HACK: use the extra loop to make sure the last node reaches to the trees depth
        // if no children, complete the rest of the tree with nulls
        if (Object.keys(children).length === 0)
          buildNullBranch(children, nextNodeDepth);
        // nextNode is not a child; go up a level and try again
        index--;
        break;
      }
      if (ALL_CHANNEL_KEYS.indexOf(nextNode.field) === nextNodeDepth) {
        children[nextNode.key] = buildChildren(nextNode, nextNodeDepth + 1);
      } else {
        // missing category
        index--;
        children[null] = { ...buildChildren(node, nextNodeDepth + 1) };
      }
    }
    return children;
  }

  // loop over level 0 filteredNodes
  while (++index < selectedNodes.length) {
    const node = selectedNodes[index];
    data[node.key] = buildChildren(node, 1);
  }
  return data;
}

export function buildExpansionData(gridApi) {
  const nodes = getGroupNodes(gridApi);
  const data = {};
  let index = -1;

  function buildChildren(node) {
    let children = {};
    while (++index < nodes.length) {
      const nextNode = nodes[index];
      if (node.level >= nextNode.level) {
        // nextNode is not a child; go up a level and try again
        index--;
        break;
      }
      if (EXPANSION_CATEGORY_KEYS.includes(nextNode.field)) {
        // add nextNode as a child and assign it's children
        children[nextNode.key] = buildChildren(nextNode);
      } else {
        // skip over nextNode and merge it's children with node's children
        children = { ...children, ...buildChildren(nextNode) };
      }
    }
    if (!node.expanded || Object.keys(children).length === 0) return null;
    return children;
  }

  // loop over level 0 nodes
  while (++index < nodes.length) {
    const node = nodes[index];
    data[node.key] = buildChildren(node);
  }
  return data;
}

export function ascendingPeriodSorter(a, b) {
  if (a.period == null) return 1;
  if (b.period == null) return -1;
  return a.period - b.period;
}

// result stores the sum of the spend columns and expansion category metadata
export function getExpansionTotalsData(
  data,
  expansionData,
  selectionData,
  sumFields = { final_spend: undefined },
  channelKeysOverride
) {
  return data?.reduce((categories, d) => {
    const categoryVector = getCategoryVector(d, expansionData);
    const name = categoryVectorToCategoryName(categoryVector);
    if (
      !isSelected(
        d,
        selectionData,
        channelKeysOverride,
        SELECTION_CATEGORY_KEYS
      )
    ) {
      return categories;
    }
    function defaultAgg(fieldValue, currentSumValue, currentAccValue, d) {
      return (
        (currentSumValue || 0) + (fieldValue || 0) + (currentAccValue || 0)
      );
    }
    return {
      ...categories,
      [name]: {
        ...ALL_CHANNEL_KEYS.reduce(
          (acc, key) => ({
            ...acc,
            [key]: d[key],
          }),
          {}
        ),
        categoryVector,
        ...Object.entries(sumFields).reduce(
          (acc, [field, customAgg]) => ({
            ...acc,
            [field]: (typeof customAgg === "function" ? customAgg : defaultAgg)(
              d[field],
              categories[name]?.[field],
              acc[field],
              d
            ),
          }),
          {}
        ),
      },
    };
  }, {});
}

export function getConstraint(constraints, currentKey, categoryMap) {
  const currentKeyIndex = ALL_CHANNEL_KEYS.indexOf(currentKey);
  return constraints.find((cat) => {
    return ALL_CHANNEL_KEYS.map((key, index) =>
      index <= currentKeyIndex
        ? // TODO determine why the undefine and null values no longer match up here
          cat[key] === categoryMap?.[key] ||
          (cat[key] == null && cat[key] == categoryMap?.[key])
        : cat[key] == null
    ).every((v) => v === true);
  });
}

// map from category name to the data of the weeks
// with undefined expansion data will use full category names.
// sorted by period ascending. null values (carryforward) at end
// returns original entry objects
export function getCategoryRowDataMap(data, expansionData) {
  return Object.fromEntries(
    Object.entries(
      data.reduce((categories, d) => {
        const name = getCategoryName(d, expansionData);
        return {
          ...categories,
          [name]: { rowData: [...(categories[name]?.rowData || []), d] },
        };
      }, {})
    ).map(([key, value]) => [
      key,
      {
        ...value,
        categoryVector: getCategoryVector(value.rowData[0], expansionData),
        rowData: value.rowData.sort(ascendingPeriodSorter),
      },
    ])
  );
}

export function getTargetWeekMap(weeks, targets, channelKeys) {
  const result = {};
  const targetNameSet = new Set(
    targets.map((t) => getNameFromTarget(t, channelKeys))
  );
  weeks.forEach((week) => {
    const name = getNameFromTarget(week, channelKeys);
    if (targetNameSet.has(name)) {
      if (!result[name]) {
        result[name] = {};
      }
      result[name][week.period] = week;
    }
  });
  return result;
}

export function getUniqueResults(duplicatedResults) {
  // media category name to set of period already in the result
  const usedMap = {};
  const results = duplicatedResults.filter((item) => {
    const name = categoryVectorToCategoryName(
      ALL_MEDIA_CHANNEL_KEYS.map((k) => item[k]).filter((v) => v != null)
    );
    if (!usedMap[name]) {
      usedMap[name] = new Set();
    }
    const periodSet = usedMap[name];
    // console.log("getUniqueResults", name, periodSet, item);
    if (periodSet.has(item.period)) {
      return false;
    }
    periodSet.add(item.period);
    return true;
  });

  return results;
}
