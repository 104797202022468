import { useVariableValue } from "@devcycle/react-client-sdk";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  restrictToFirstScrollableAncestor,
  restrictToParentElement,
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from "@dnd-kit/modifiers";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Box, InputLabel } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useSetMediaHierarchyMutation } from "../../app/scenarioMetadataApi";
import { selectScenario } from "../../app/scenarioSlice";
import { AspirationType, getChannelDisplayName } from "../../constants";
import OptimizationLevelButton from "./OptimizationLevelButton";
import {
  useGetOptimizationLevelQuery,
  useSetOptimizationLevelMutation,
} from "./optimizationLevelApi";

const OptimizationLevels = ({ sx }) => {
  const flightingFlag = useVariableValue("flighting", false);

  const {
    id: scenarioId,
    userId,
    isScenarioReady,
    aspiration,
    mediaHierarchy,
  } = useSelector(selectScenario);

  // INITIALIZE VALUE
  const { data: optimizationLevel } = useGetOptimizationLevelQuery(
    {
      userId,
      scenarioId,
    },
    {
      skip: !isScenarioReady,
    }
  );

  // set to lowest non-flighting level when flighting turned off
  useEffect(() => {
    if (optimizationLevel == null || aspiration === AspirationType.Simulate) {
      return;
    }
    if (!flightingFlag && optimizationLevel > mediaHierarchy.length) {
      setOptimizationLevel({
        userId,
        scenarioId,
        optimizationLevel: mediaHierarchy.length,
      });
    }
  }, [flightingFlag, optimizationLevel, mediaHierarchy, aspiration]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const [setOptimizationLevel] = useSetOptimizationLevelMutation();
  const [setMediaHierarchy] = useSetMediaHierarchyMutation();

  const label =
    aspiration === AspirationType.Simulate
      ? "Simulate Level"
      : aspiration === AspirationType.Grow
      ? "Grow Level"
      : "Optimize Level";

  return (
    <Box sx={sx}>
      <InputLabel shrink>{label}</InputLabel>
      <Box>
        <DndContext
          autoScroll={false}
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        >
          <SortableContext
            items={mediaHierarchy}
            strategy={verticalListSortingStrategy}
          >
            <Box>
              {[...mediaHierarchy, "period"].map((key, index) => (
                <OptimizationLevelButton
                  key={key}
                  id={key}
                  name={
                    key === "period" ? "Period" : getChannelDisplayName(key)
                  }
                  draggable={key !== "period"}
                  selected={index < optimizationLevel}
                  disabled={key === "period" && !flightingFlag}
                  onChange={toggleLevel}
                  borderStyle={
                    index === 0 ? "top" : key === "period" ? "bottom" : "middle"
                  }
                />
              ))}
            </Box>
          </SortableContext>
        </DndContext>
      </Box>
    </Box>
  );

  function handleDragEnd(event) {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = mediaHierarchy.findIndex((k) => k === active.id);
      const newIndex = mediaHierarchy.findIndex((k) => k === over.id);
      const newMediaHierarchy = arrayMove(mediaHierarchy, oldIndex, newIndex);
      if (mediaHierarchy.every((v, i) => v === newMediaHierarchy[i])) {
        return;
      }
      console.log(
        "newMediaHierarchy",
        newMediaHierarchy,
        oldIndex,
        newIndex,
        active,
        over
      );

      setMediaHierarchy({
        scenarioId,
        userId,
        mediaHierarchy: newMediaHierarchy,
      });
    }
  }

  function toggleLevel(newKey) {
    if (aspiration === AspirationType.Simulate) {
      return;
    }
    let optimizationLevel = 1 + mediaHierarchy.indexOf(newKey);
    if (newKey === "period") {
      optimizationLevel = mediaHierarchy.length + 1;
    }
    console.log("newKey", newKey, optimizationLevel);
    setOptimizationLevel({
      userId,
      scenarioId,
      optimizationLevel,
    });
  }
};

export default OptimizationLevels;
