import { TextField } from "@mui/material";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import CurrencyInput from "react-currency-input-field";
import classes from "./Sliders.module.css";

// https://github.com/cchanxzy/react-currency-input-field/issues/111#issuecomment-774324981
const CurrencyInputCustom = ({ onChange, ...props }) => {
  return (
    <CurrencyInput
      {...props}
      onValueChange={(value, name, values) =>
        onChange({
          target: { value, name, values },
        })
      }
    />
  );
};

function roundDecimals(v, places) {
  return Math.round(v * Math.pow(10, places)) / Math.pow(10, places);
}

function FormattedInput({
  label,
  baseValue,
  update,
  currencyInputProps,
  blurDecimalsOverride,
  focusDecimalsOverride,
}) {
  const [rawValue, setRawValue] = useState(null);
  const [floatValue, setFloatValue] = useState(null);
  const isFocused = useRef(false);
  const focusStartValue = useRef(false);

  function roundFloat(value) {
    return roundDecimals(
      value,
      currencyInputProps?.decimalScale ?? currencyInputProps?.decimalsLimit ?? 2 // 2 is currency input decimalLimit default
    );
  }

  function getOverrideValue(value) {
    if (!isFocused.current && blurDecimalsOverride !== undefined)
      return value.toFixed(blurDecimalsOverride);
    if (isFocused.current && focusDecimalsOverride !== undefined)
      return value.toFixed(focusDecimalsOverride);
    return roundFloat(baseValue).toString();
  }

  useEffect(() => {
    if (isFocused.current) return;
    setRawValue(
      baseValue == null ? baseValue : getOverrideValue(baseValue).toString()
    );
    setFloatValue(baseValue == null ? baseValue : roundFloat(baseValue));
  }, [baseValue]);

  const InputComponent = useCallback(
    forwardRef((props, ref) => (
      <CurrencyInputCustom {...currencyInputProps} {...props} />
    )),
    [currencyInputProps]
  );

  return (
    <TextField
      label={label}
      variant="standard"
      className={classes.finalInput}
      InputLabelProps={{
        shrink: true,
      }}
      sx={{ my: 1 }}
      value={rawValue}
      onChange={({
        target: {
          value: newRawValue,
          values: { float },
        },
      }) => {
        setRawValue(newRawValue);
        setFloatValue(float);
      }}
      InputProps={{
        inputComponent: InputComponent,
      }}
      onFocus={() => {
        isFocused.current = true;
        setRawValue(getOverrideValue(floatValue).toString());
        focusStartValue.current = roundFloat(floatValue);
      }}
      onBlur={() => {
        isFocused.current = false;
        setRawValue(getOverrideValue(floatValue).toString());
        if (floatValue !== focusStartValue.current) {
          update(floatValue);
        }
      }}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          if (floatValue !== focusStartValue.current) {
            update(floatValue);
          }
          focusStartValue.current = floatValue;
          event.preventDefault();
        }
      }}
    />
  );
}

export default FormattedInput;
