import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { createSelector } from "@reduxjs/toolkit";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  useGetMediaGroupQuery,
  useGetMediaGroupsQuery,
  useUpdateConstraintGroupMutation,
  useUpdateGroupMutation,
} from "../../app/scenarioApi.js";
import {
  useSetGrowthTargetMutation,
  useSetMediaBudgetMutation,
} from "../../app/scenarioMetadataApi.js";
import {
  selectExpandedAndSelectedShortMediaTargets,
  selectScenario,
} from "../../app/scenarioSlice.js";
import LoadingSection, {
  LoadingType,
} from "../../components/LoadingSection/LoadingSection.jsx";
import { AspirationType, isOptimizationAspiration } from "../../constants.js";
import { getNameFromTarget } from "../../containers/CategoryDataUtils.js";
import { optimizationInfinity } from "../../scenarioDataUtils/scenarioDataUtils.js";
import { objectFilter, objectMap } from "../../utils/objectUtil.js";
import FormattedInput from "./FormattedInput.jsx";
import classes from "./Sliders.module.css";
import SpendSlider from "./SpendSlider.jsx";

export const SliderModeEnum = {
  Percent: "percent",
  Dollar: "dollar",
};

export const SliderSourceTypeEnum = {
  Spend: "spend",
  Constraint: "constraint",
};

const dollarInputProps = {
  intlConfig: {
    currency: "USD",
  },
  decimalsLimit: 2,
  allowNegativeValue: false,
};

const percentInputProps = {
  suffix: "%",
  decimalsLimit: 1,
};

const spendPx = 4;

function SpendControls() {
  const [sliderMode, setSliderMode] = useState(SliderModeEnum.Percent);

  const {
    id: scenarioId,
    userId,
    isScenarioReady,
    aspiration,
    mediaBudget,
    growthTarget,
    mediaHierarchy,
  } = useSelector(selectScenario);

  const selectTotalsDataBase = useMemo(() => {
    return createSelector(
      (result) => result.data,
      (data) => {
        if (!data) return data;
        console.log(data);
        return objectFilter(data, ([k]) =>
          ["starting_spend", "final_spend"].includes(k)
        );
      }
    );
  }, []);

  const {
    totalsDataBase,
    isLoading: totalGroupIsLoading,
    isSuccess: totalGroupIsSuccess,
  } = useGetMediaGroupQuery(
    {
      userId,
      scenarioId,
      target: {},
      hierarchy: mediaHierarchy,
    },
    {
      skip:
        scenarioId == null ||
        userId == null ||
        scenarioId === 0 ||
        !isScenarioReady,
      selectFromResult: (result) => ({
        ...result,
        totalsDataBase: selectTotalsDataBase(result),
      }),
    }
  );

  const targets = useSelector(selectExpandedAndSelectedShortMediaTargets);

  // https://redux.js.org/tutorials/essentials/part-8-rtk-query-advanced#selecting-values-from-results
  const selectSliderDataBase = useMemo(() => {
    return createSelector(
      (result) => result.data,
      (data) => {
        if (!data) return data;
        // console.log(data);
        return data.reduce(
          (acc, g) => ({
            ...acc,
            [getNameFromTarget(g, mediaHierarchy)]: objectFilter(g, ([k]) =>
              [
                ...mediaHierarchy,
                "starting_spend",
                "final_spend",
                "lower_spend",
                "upper_spend",
              ].includes(k)
            ),
          }),
          {}
        );
      }
    );
  }, [mediaHierarchy]);

  const {
    slidersDataBase,
    isLoading: slidersDataBaseIsLoading,
    isFetching: slidersDataBaseIsFetching,
    isSuccess: slidersDataBaseIsSuccess,
  } = useGetMediaGroupsQuery(
    {
      scenarioId,
      userId,
      targets,
      hierarchy: mediaHierarchy,
    },
    {
      skip: !isScenarioReady || targets === null,
      selectFromResult: (result) => ({
        ...result,
        slidersDataBase: selectSliderDataBase(result),
      }),
    }
  );

  const sliderSourceType = useMemo(() => {
    return isOptimizationAspiration(aspiration)
      ? SliderSourceTypeEnum.Constraint
      : SliderSourceTypeEnum.Spend;
  }, [aspiration]);

  const isLoading = useMemo(() => {
    return (
      slidersDataBaseIsLoading || !slidersDataBaseIsSuccess || !isScenarioReady
    );
  }, [slidersDataBaseIsLoading, slidersDataBaseIsSuccess, isScenarioReady]);

  const [updateGroup] = useUpdateGroupMutation();
  const [updateConstraintGroup] = useUpdateConstraintGroupMutation();
  const updateTotalSpend = useCallback(
    async (newTotalSpend) =>
      await updateGroup({
        collectionName: "media",
        updateMap: {
          final_spend: newTotalSpend,
        },
        userId,
        scenarioId,
        target: {},
      }),
    [updateGroup, userId, scenarioId]
  );

  const [setMediaBudget] = useSetMediaBudgetMutation();
  const [setGrowthTarget] = useSetGrowthTargetMutation();

  async function sliderUpdate({ target, updateMap }) {
    const collectionName =
      sliderSourceType === SliderSourceTypeEnum.Spend ? "media" : "constraints";

    if (sliderSourceType === SliderSourceTypeEnum.Spend) {
      // Round to the nearest dollar to make the data cleaner
      // TODO This will round both values of a constraint. Is that a problem?
      const roundedUpdateMap = objectMap(updateMap, ([k, v]) => [
        k,
        Math.round(v),
      ]);
      await updateGroup({
        collectionName,
        updateMap: roundedUpdateMap,
        userId,
        scenarioId,
        target,
      });
      return;
    }

    // All constraints are rounded to nearest cent
    const roundedUpdateMap = objectMap(updateMap, ([k, v]) => [
      k,
      Math.round(v * 100) / 100,
    ]);

    await updateConstraintGroup({
      updateMap: roundedUpdateMap,
      userId,
      scenarioId,
      target,
      mediaHierarchy,
    });
  }

  function onSpendChange(key, update) {
    // TODO? Update total spend in real time as a final spend slider moves
    // console.log("onSpendChange", key, update);
  }

  const getSliderBounds = useCallback(
    (spendGroup) => {
      if (sliderMode === SliderModeEnum.Percent) {
        return [
          spendGroup.starting_spend * 0.8,
          spendGroup.starting_spend * 1.2,
        ];
      }
      if (sliderSourceType === SliderSourceTypeEnum.Constraint) {
        if (mediaBudget === optimizationInfinity) {
          return [0, totalsDataBase.starting_spend];
        }
        return [0, mediaBudget || 0];
      }
      return [0, Math.max((totalsDataBase.final_spend || 0) * 1.2, 1000000)];
    },
    [sliderMode, mediaBudget, totalsDataBase]
  );

  return (
    <LoadingSection isLoading={isLoading} type={LoadingType.Hide}>
      <Box sx={{ px: spendPx }}>
        <Box
          className={classes.slidersContainer}
          sx={{
            overflowY: "auto",
            overflowX: "clip",
            flex: "1 1 auto",
            px: spendPx,
            mx: -spendPx,
            py: 1.1,
          }}
          elevation={0}
          variant="elevation"
        >
          {aspiration === AspirationType.Simulate ? (
            <FormattedInput
              label={"Total Spend"}
              baseValue={totalsDataBase?.final_spend}
              update={(newValue) => {
                console.log("Persist Total Spend", newValue);
                updateTotalSpend(newValue);
              }}
              currencyInputProps={dollarInputProps}
              blurDecimalsOverride={0}
              focusDecimalsOverride={2}
            />
          ) : (
            <></>
          )}
          {aspiration === AspirationType.Grow ? (
            <FormattedInput
              label={"Growth Target"}
              baseValue={growthTarget * 100}
              update={(_newValue) => {
                const newValue = _newValue / 100;
                console.log("Persist Growth Target", newValue);
                setGrowthTarget({
                  userId,
                  scenarioId,
                  growthTarget: newValue,
                });
              }}
              currencyInputProps={percentInputProps}
            />
          ) : (
            <></>
          )}
          {aspiration === AspirationType.Optimize ||
          aspiration === AspirationType.Grow ? (
            <FormattedInput
              label={"Media Budget"}
              baseValue={
                aspiration === AspirationType.Grow &&
                mediaBudget === optimizationInfinity
                  ? null
                  : mediaBudget
              }
              update={(_newValue) => {
                const newValue = _newValue ?? optimizationInfinity;
                console.log("Persist Media Budget", newValue);
                setMediaBudget({
                  userId,
                  scenarioId,
                  mediaBudget: newValue,
                });
              }}
              currencyInputProps={dollarInputProps}
              blurDecimalsOverride={0}
              focusDecimalsOverride={2}
            />
          ) : (
            <></>
          )}
          {/* <Box sx={{ mt: "0.5em" }}></Box> */}
          <ToggleButtonGroup
            color="primary"
            value={sliderMode}
            exclusive
            onChange={(_, v) => {
              if (v !== null) setSliderMode(v);
            }}
            sx={{ py: 1 }}
          >
            {/* 22px to hold padding; 1ch for label; 18px to make more square-like */}
            <ToggleButton
              value={SliderModeEnum.Percent}
              sx={{ width: "calc(22px + 1ch + 12px)" }}
            >
              %
            </ToggleButton>
            <ToggleButton
              value={SliderModeEnum.Dollar}
              sx={{ width: "calc(22px + 1ch + 12px)" }}
            >
              $
            </ToggleButton>
          </ToggleButtonGroup>
          {slidersDataBase ? (
            Object.entries(slidersDataBase)
              // todo remove category keys
              .map(([categoryKey, spendGroup]) => (
                <SpendSlider
                  key={categoryKey}
                  sliderMode={sliderMode}
                  sliderSourceType={sliderSourceType}
                  spendGroup={spendGroup}
                  spendGroupIsFetching={slidersDataBaseIsFetching}
                  update={sliderUpdate}
                  onSpendChange={(...args) =>
                    onSpendChange(categoryKey, ...args)
                  }
                  getSliderBounds={getSliderBounds}
                />
              ))
          ) : (
            <></>
          )}
        </Box>
      </Box>
    </LoadingSection>
  );
}

export default SpendControls;
