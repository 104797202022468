import * as am5 from "@amcharts/amcharts5";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import * as am5xy from "@amcharts/amcharts5/xy";
import { useVariableValue } from "@devcycle/react-client-sdk";
import { Box, FormControl, MenuItem, Select } from "@mui/material";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetMediaGroupsQuery } from "../../../app/scenarioApi";
import {
  selectExpandedAndSelectedShortMediaTargets,
  selectScenario,
} from "../../../app/scenarioSlice";
import LoadingSection, {
  LoadingType,
} from "../../../components/LoadingSection/LoadingSection";
import {
  colors,
  defaultExportFormats,
  finalName,
  getColorBySeriesKey,
  resultName,
  startingName,
} from "../../../constants";
import { useOnScenarioDataPersist } from "../../../hooks/onScenarioDataPersist";
import { ScenarioDataKeys } from "../../../scenarioDataUtils/scenarioDataUtils";
import {
  addAdaptiveYAxes,
  addAxisRange,
  addBigNumberPrefixes,
  addLegend,
  addLineSeries,
  addTitle,
  addValueAxis,
  addZeroBigNumberPrefix,
  axisLabelSeriesSeparator,
  bigNumberFormatString,
  dollarsFormatString,
  getDefaultTheme,
  roiDollarsFormatString,
  setupZoomOutButton,
} from "../../../utils/am5ChartUtils";
import {
  getNameWithoutDuplicates,
  getTargetFromObject,
  getTargetWithoutDuplicates,
} from "../../../utils/targetUtils";
import ChartControls from "../ChartControls";
import { useLazyGetCurvesChartQuery } from "./curvesChartApi";
import {
  addSeriesData,
  ChartRangesEnum,
  ChartSeriesEnum,
  reinitialize,
  selectCurvesChartData,
  selectCurvesChartSeriesProperties,
  selectTargetInfo,
  selectValidSeriesDataKeys,
  setCurrentTarget,
  setSeriesProperty,
  setTargetInfo,
  UnderOverKey,
} from "./curvesChartSlice";
import { buildGetFallbackTarget } from "./getFallbackTarget";

const CHART_ID = "response-chart";

const getChartTitleText = (targetName) => `${targetName} Curves`;

const underOverRangeKeys = [
  ChartRangesEnum.UnderInvestment,
  ChartRangesEnum.OverInvestment,
];

const rangeKeys = [
  ChartRangesEnum.StartingSpend,
  ChartRangesEnum.FinalSpend,
  ChartRangesEnum.ProfitMax,
  UnderOverKey,
];

const seriesInfo = {
  [ChartSeriesEnum.Profit]: {
    name: "Profit",
    color: getColorBySeriesKey("final_profit"),
  },
  [ChartSeriesEnum.MarginalRoi]: {
    name: "Marginal ROI",
    color: colors.purple,
  },
  [ChartSeriesEnum.Contribution]: {
    name: resultName,
    color: getColorBySeriesKey("final_contribution"),
  },
  [ChartSeriesEnum.Roi]: {
    name: "ROI",
    color: getColorBySeriesKey("final_roi"),
  },
  [ChartRangesEnum.UnderInvestment]: {
    name: "Under Investment",
    color: "#d5d5d5",
  },
  [ChartRangesEnum.OverInvestment]: {
    name: "Over Investment",
    color: "#d5d5d5",
  },
  [UnderOverKey]: {
    name: "Under / Over",
    color: "#d5d5d5",
  },
  [ChartRangesEnum.ProfitMax]: {
    name: "Profit Max",
    color: "#d5d5d5",
  },
  [ChartRangesEnum.StartingSpend]: {
    name: startingName,
    color: getColorBySeriesKey("starting_spend"),
  },
  [ChartRangesEnum.FinalSpend]: {
    name: finalName,
    color: getColorBySeriesKey("final_spend"),
  },
};

const PROFIT_MAX_ZOOM_MULTIPLE = 1.2;
// To protect from a bad profit max value,
// limit the zoom to a percentage of max spend
const MAX_SPEND_MIN_ZOOM_MULTIPLE = 0.05;

const CurvesChart = ({
  isInPresentationMode,
  enterPresentationMode,
  exitPresentationMode,
  presentationModePopOverContainer,
}) => {
  const curvesChartAutoZoomFlag = useVariableValue(
    "curves-chart-auto-zoom",
    false
  );

  const data = useSelector(selectCurvesChartData);
  const seriesProperties = useSelector(selectCurvesChartSeriesProperties);
  const { targetMap, currentTarget, currentTargetName } =
    useSelector(selectTargetInfo);
  const validSeriesDataKeys = useSelector(selectValidSeriesDataKeys);
  // Set the the name of the channel that errored. Cleared when another channel is selected
  const [currentErrorKey, setCurrentErrorKey] = useState(null);
  const dispatch = useDispatch();

  const [getCurvesChart] = useLazyGetCurvesChartQuery();

  const [updateLoadingKeys, setUpdateLoadingKeys] = useState([]);

  const {
    isScenarioReady,
    id: scenarioId,
    userId,
    mediaHierarchy,
    salesHierarchy,
  } = useSelector(selectScenario);

  const targets = useSelector(selectExpandedAndSelectedShortMediaTargets);

  const {
    data: sortedMedia,
    isLoading: sortedMediaIsLoading,
    isFetching: sortedMediaIsFetching,
    isSuccess: sortedMediaIsSuccess,
  } = useGetMediaGroupsQuery(
    {
      scenarioId,
      userId,
      targets,
      hierarchy: mediaHierarchy,
    },
    {
      skip: !isScenarioReady || targets === null,
    }
  );

  const sortedTargets = useMemo(
    () => sortedMedia?.map(getTargetFromObject) ?? null,
    [sortedMedia]
  );

  const isSortedTargetsReady = useMemo(
    () =>
      sortedTargets != null &&
      !sortedMediaIsLoading &&
      !sortedMediaIsFetching &&
      sortedMediaIsSuccess
  );

  const isUpdateLoading = useMemo(
    () => updateLoadingKeys.includes(currentTargetName),
    [updateLoadingKeys, currentTargetName]
  );

  const manageUpdateLoading = useMemo(() => {
    let outstandingShows = {};
    return {
      show: (k) => {
        outstandingShows[k] = (outstandingShows[k] ?? 0) + 1;
        setUpdateLoadingKeys((keys) => [...new Set(keys.concat(k))]);
      },
      hide: (k) => {
        outstandingShows[k] = (outstandingShows[k] ?? 0) - 1;
        if (outstandingShows[k] === 0) {
          setUpdateLoadingKeys((keys) => keys.filter((key) => key !== k));
        }
      },
    };
  }, [setUpdateLoadingKeys]);

  const [title, setTitle] = useState(null);

  const isLoading = useMemo(
    () =>
      (!data && (validSeriesDataKeys.length === 0 || !isUpdateLoading)) ||
      sortedMediaIsLoading ||
      title == null, // handles switching back when target's data was changed
    [data, sortedMediaIsLoading, isUpdateLoading, validSeriesDataKeys, title]
  );

  const series = useRef({});
  const axisRanges = useRef({});

  const root = useRef(null);
  const chart = useRef(null);
  const legend = useRef(null);
  const chartTitleLabel = useRef(null);
  const yAxisLeftLabel = useRef(null);
  const yAxisRightLabel = useRef(null);
  const xAxisBottom = useRef(null);
  const xAxisTop = useRef(null);

  const currentDataTarget = useRef(null);

  const [exporting, setExporting] = useState(null);

  useLayoutEffect(() => {
    dispatch(reinitialize(scenarioId));
  }, [scenarioId]);

  // SETUP CHART
  useLayoutEffect(() => {
    root.current = am5.Root.new(CHART_ID);

    root.current.setThemes([getDefaultTheme(root.current)]);

    addBigNumberPrefixes(root.current);

    chart.current = root.current.container.children.push(
      am5xy.XYChart.new(root.current, {
        panY: false,
        layout: root.current.verticalLayout,
        cursor: am5xy.XYCursor.new(root.current, { behavior: "zoomX" }),
      })
    );

    chart.current.plotContainer.setAll({ cursorOverStyle: "crosshair" });

    setupZoomOutButton(root.current, chart.current);

    const { axis: xAxisBottomCurrent } = addValueAxis({
      root: root.current,
      axes: chart.current.xAxes,
      axisOptions: {
        strictMinMax: true,
      },
      labelOptions: {
        text: "Spend",
      },
      labelIndex: -1,
    });
    addZeroBigNumberPrefix(xAxisBottomCurrent);
    xAxisBottom.current = xAxisBottomCurrent;

    const { axis: xAxisTopCurrent } = addValueAxis({
      root: root.current,
      axes: chart.current.xAxes,
      opposite: true,
      axisOptions: {
        strictMinMax: true,
        numberFormat: bigNumberFormatString,
      },
      labelOptions: {
        text: "Impressions",
      },
      labelIndex: 0,
    });
    addZeroBigNumberPrefix(xAxisTopCurrent);
    xAxisTop.current = xAxisTopCurrent;

    const { axis: yAxisLeft, axisLabel: yAxisLeftLabelCurrent } = addValueAxis({
      root: root.current,
      axes: chart.current.yAxes,
      direction: "y",
      axisOptions: {
        extraMax: 0.05,
        extraTooltipPrecision: 2,
        numberFormat: roiDollarsFormatString,
      },
      labelIndex: 0,
    });
    yAxisLeftLabel.current = yAxisLeftLabelCurrent;

    const { axis: yAxisRight, axisLabel: yAxisRightLabelCurrent } =
      addValueAxis({
        root: root.current,
        axes: chart.current.yAxes,
        direction: "y",
        opposite: true,
        axisOptions: {
          extraMax: 0.05,
        },
        labelIndex: -1,
      });
    addZeroBigNumberPrefix(yAxisRight);
    yAxisRightLabel.current = yAxisRightLabelCurrent;

    // Add series
    series.current[ChartSeriesEnum.Profit] = addLineSeries({
      root: root.current,
      chart: chart.current,
      seriesOptions: {
        name: seriesInfo[ChartSeriesEnum.Profit].name,
        xAxis: xAxisTopCurrent,
        yAxis: yAxisRight,
        valueXField: "impressions",
        valueYField: "profit",
      },
      numberFormat: dollarsFormatString,
      color: seriesInfo[ChartSeriesEnum.Profit].color,
      backgroundSeriesOptions: {},
    });
    series.current[ChartSeriesEnum.MarginalRoi] = addLineSeries({
      root: root.current,
      chart: chart.current,
      seriesOptions: {
        name: seriesInfo[ChartSeriesEnum.MarginalRoi].name,
        xAxis: xAxisBottom.current,
        yAxis: yAxisLeft,
        valueXField: "spend",
        valueYField: "marginal_roi",
      },
      numberFormat: roiDollarsFormatString,
      color: seriesInfo[ChartSeriesEnum.MarginalRoi].color,
      backgroundSeriesOptions: {},
    });
    series.current[ChartSeriesEnum.Contribution] = addLineSeries({
      root: root.current,
      chart: chart.current,
      seriesOptions: {
        name: seriesInfo[ChartSeriesEnum.Contribution].name,
        xAxis: xAxisTopCurrent,
        yAxis: yAxisRight,
        valueXField: "impressions",
        valueYField: "contribution",
      },
      numberFormat: dollarsFormatString,
      color: seriesInfo[ChartSeriesEnum.Contribution].color,
      backgroundSeriesOptions: {},
    });
    series.current[ChartSeriesEnum.Roi] = addLineSeries({
      root: root.current,
      chart: chart.current,
      seriesOptions: {
        name: seriesInfo[ChartSeriesEnum.Roi].name,
        xAxis: xAxisBottom.current,
        yAxis: yAxisLeft,
        valueXField: "spend",
        valueYField: "roi",
      },
      numberFormat: roiDollarsFormatString,
      color: seriesInfo[ChartSeriesEnum.Roi].color,
      backgroundSeriesOptions: {},
    });

    addAdaptiveYAxes({
      axesInfo: [
        {
          axis: yAxisLeft,
          series: [ChartSeriesEnum.MarginalRoi, ChartSeriesEnum.Roi].map(
            (k) => series.current[k]
          ),
          side: "left",
          desiredSide: "left",
        },
        {
          axis: yAxisRight,
          series: [ChartSeriesEnum.Profit, ChartSeriesEnum.Contribution].map(
            (k) => series.current[k]
          ),
          side: "right",
          desiredSide: "left",
        },
      ],
    });

    Object.values(ChartSeriesEnum).forEach((seriesKey) => {
      series.current[seriesKey].seriesKey = seriesKey;
    });

    Object.values(ChartRangesEnum)
      .reverse()
      .forEach((rangeKey) => {
        axisRanges.current[rangeKey] = addAxisRange({
          axis: xAxisBottom.current,
          data: {},
          color: seriesInfo[rangeKey].color,
          ...(rangeKey === ChartRangesEnum.ProfitMax && {
            gridOptions: { strokeDasharray: 3 },
          }),
        });
        axisRanges.current[rangeKey].rangeKey = rangeKey;
      });

    legend.current = addLegend({
      root: root.current,
      chart: chart.current,
      options: {
        clickTarget: "none",
      },
      onClick: (e) => {
        const { seriesKey, rangeKey } = e.target._dataItem.dataContext;
        if (seriesKey) {
          dispatch(setSeriesProperty({ seriesKey, key: "isVisible" }));
        }
        if (rangeKey) {
          dispatch(
            setSeriesProperty({ seriesKey: rangeKey, key: "isVisible" })
          );
        }
      },
    });

    legend.current.itemContainers.template.setAll({
      cursorOverStyle: "pointer",
    });

    // Set default marker height to 2
    legend.current.markers.template.setAll({
      height: 2,
    });

    legend.current.data.setAll([
      ...[
        ChartSeriesEnum.Profit,
        ChartSeriesEnum.MarginalRoi,
        ChartSeriesEnum.Contribution,
        ChartSeriesEnum.Roi,
      ].map((key) => series.current[key]),
      ...rangeKeys.map((key) => ({
        name: seriesInfo[key].name,
        color: am5.color(seriesInfo[key].color),
        rangeKey: key,
      })),
    ]);

    legend.current.itemContainers.values.forEach((itemContainer) => {
      const rangeKey = itemContainer.dataItem.dataContext.rangeKey;

      if (rangeKeys.includes(rangeKey)) {
        itemContainer.dataItem.get("markerRectangle").set("strokeOpacity", 0);
      }

      if (rangeKey === UnderOverKey) {
        itemContainer.dataItem.get("marker").set("height", 18);
      }

      if (rangeKey === ChartRangesEnum.ProfitMax) {
        itemContainer.dataItem.get("marker").children.push(
          am5.Graphics.new(root.current, {
            stroke: seriesInfo[ChartRangesEnum.ProfitMax].color,
            strokeWidth: 2,
            strokeOpacity: 1,
            strokeDasharray: 3,
            draw: (display) => {
              display.moveTo(0, 1);
              display.lineTo(18, 1);
            },
          })
        );
        itemContainer.dataItem.get("markerRectangle").set("forceHidden", true);
      }
    });

    chartTitleLabel.current = addTitle({
      root: root.current,
      chart: chart.current,
      options: {
        opacity: 0,
      },
    });

    return () => {
      root.current.dispose();
    };
  }, []);

  // UPDATE DATA ON SCENARIO DATA CHANGE
  //curve invalidation is in Home.jsx

  const updateDataOnPersist = useCallback(
    async (shouldAbort = () => false, changes = []) => {
      if (currentDataTarget.current == null) return;
      if (validSeriesDataKeys.includes(currentTargetName)) return;
      manageUpdateLoading.show(currentTargetName);
      const { data, isError } = await getCurvesChart({
        userId,
        scenarioId,
        target: currentDataTarget.current,
        mediaHierarchy,
        salesHierarchy,
      });
      const aborting = shouldAbort();
      // console.log({ data, isError, aborting });
      if (!aborting && !isError) {
        dispatch(addSeriesData(data));
      }
      if (isError) {
        setCurrentErrorKey(currentTargetName);
      }
      manageUpdateLoading.hide(currentTargetName);
    },
    [validSeriesDataKeys]
  );

  const [onPersistKeys] = useState([
    ScenarioDataKeys.Results,
    ScenarioDataKeys.Media,
    ScenarioDataKeys.Sales,
  ]);

  useOnScenarioDataPersist(onPersistKeys, updateDataOnPersist);

  // TODO validSeriesDataKeys is causing this to run when a series is toggled
  // even though it doesn't change. Try to fix
  // Update data on target change
  useLayoutEffect(() => {
    const isTargetValid = (t) => t != null && Object.keys(t).length !== 0;
    if (!isTargetValid(currentTarget)) return;
    currentDataTarget.current = currentTarget;
    if (
      [...validSeriesDataKeys, ...updateLoadingKeys, currentErrorKey].includes(
        currentTargetName
      )
    )
      return;
    (async () => {
      manageUpdateLoading.show(currentTargetName);
      const { data, isError } = await getCurvesChart({
        userId,
        scenarioId,
        target: currentDataTarget.current,
        mediaHierarchy,
        salesHierarchy,
      });
      if (!isError) {
        dispatch(addSeriesData(data));
      } else {
        setCurrentErrorKey(currentTargetName);
      }
      manageUpdateLoading.hide(currentTargetName);
    })();
  }, [
    currentTarget,
    currentTargetName,
    validSeriesDataKeys,
    updateLoadingKeys,
    currentErrorKey,
  ]);

  // useEffect(() => {
  //   console.log("currentTarget", currentTarget);
  // }, [currentTarget]);

  // useEffect(() => {
  //   console.log("currentTargetName", currentTargetName);
  // }, [currentTargetName]);

  // useEffect(() => {
  //   console.log("validSeriesDataKeys", validSeriesDataKeys);
  // }, [validSeriesDataKeys]);

  // useEffect(() => {
  //   console.log("updateLoadingKeys", updateLoadingKeys);
  // }, [updateLoadingKeys]);

  // useEffect(() => {
  //   console.log("getNewData", getNewData);
  // }, [getNewData]);

  function setMarkerColor(dataItem, color) {
    dataItem.get("markerRectangle").set("fill", am5.color(color));
  }

  function toggleLegendItem(legend, findF, show, seriesInfoKey) {
    if (legend !== null) {
      const dataItem = legend.dataItems.find((o) => findF(o));
      if (dataItem == null) return;

      const hideColor = [
        ChartRangesEnum.UnderInvestment,
        ChartRangesEnum.OverInvestment,
      ].includes(seriesInfoKey)
        ? seriesInfo[seriesInfoKey].color
        : "#3b3b3b";

      const color = show ? seriesInfo[seriesInfoKey].color : hideColor;
      const opacity = show ? 1 : 0.4;
      setMarkerColor(dataItem, color);
      dataItem.get("itemContainer").set("opacity", opacity);
    }
  }

  // set series properties on change
  useLayoutEffect(() => {
    if (seriesProperties == null) return;

    function setVisibility(o, condition) {
      if (condition) {
        o.show();
        return;
      }
      o.hide();
    }

    // TOGGLE SERIES VISIBILITY

    Object.values(ChartSeriesEnum).forEach((key) => {
      if (series.current[key] != null) {
        setVisibility(series.current[key], seriesProperties[key].isVisible);
      }
    });

    // TOGGLE RANGE VISIBILITY

    function setRangeVisibility(key, isVisible, findF) {
      if (axisRanges.current[key] != null) {
        setVisibility(axisRanges.current[key], isVisible);
        toggleLegendItem(
          legend.current,
          findF || ((o) => o.dataContext.rangeKey === key),
          isVisible,
          key
        );
      }
    }

    rangeKeys.forEach((key) => {
      if (key === UnderOverKey) {
        underOverRangeKeys.forEach((k) =>
          setRangeVisibility(
            k,
            seriesProperties[UnderOverKey].isVisible,
            (o) => o.dataContext.rangeKey === UnderOverKey
          )
        );
        return;
      }
      setRangeVisibility(key, seriesProperties[key].isVisible);
    });

    // SET AXIS NAMES

    function getAxisNameFromKeys(axisKeys) {
      return axisKeys
        .filter((key) => seriesProperties[key].isVisible)
        .map((key) => seriesInfo[key].name)
        .join(axisLabelSeriesSeparator);
    }

    yAxisLeftLabel.current.set(
      "text",
      getAxisNameFromKeys([ChartSeriesEnum.MarginalRoi, ChartSeriesEnum.Roi])
    );
    yAxisRightLabel.current.set(
      "text",
      getAxisNameFromKeys([
        ChartSeriesEnum.Profit,
        ChartSeriesEnum.Contribution,
      ])
    );
  }, [seriesProperties]);

  useLayoutEffect(() => {
    if (title == null || chartTitleLabel.current == null) return;
    chartTitleLabel.current.set("text", title);
  }, [title]);

  const getFallbackTarget = useCallback(
    buildGetFallbackTarget(mediaHierarchy),
    [mediaHierarchy]
  );

  useEffect(() => {
    if (data == null || data.isValid === false) {
      return;
    }

    function autoZoom() {
      const cpm =
        (1000 * data.series.at(-1).spend) / data.series.at(-1).impressions;
      const spendEnd = Math.max(
        Math.min(
          PROFIT_MAX_ZOOM_MULTIPLE * data.max_profit_spend, // desired
          data.max_volume_spend // clamp max
        ),
        MAX_SPEND_MIN_ZOOM_MULTIPLE * data.max_volume_spend // clamp min
      );
      const impressionsEnd = (1000 * spendEnd) / cpm;
      xAxisBottom.current.zoomToValues(0, spendEnd);
      xAxisTop.current.zoomToValues(0, impressionsEnd);
    }

    if (curvesChartAutoZoomFlag) {
      // series choice is arbitrary
      series.current[ChartSeriesEnum.Contribution].events.once(
        "datavalidated",
        // need to run after both datavalidaiton events are complete
        () => setTimeout(() => setTimeout(autoZoom, 0), 0)
      );
    }

    axisRanges.current[ChartRangesEnum.UnderInvestment].setAll({
      value: 0,
      endValue: data.inflection_spend,
    });
    axisRanges.current[ChartRangesEnum.OverInvestment].setAll({
      value: data.max_volume_spend,
      endValue: data.series.at(-1).spend,
    });

    // set line values
    [
      ChartRangesEnum.ProfitMax,
      ChartRangesEnum.StartingSpend,
      ChartRangesEnum.FinalSpend,
    ].forEach((key) => {
      axisRanges.current[key].set("value", data[key]);
    });

    // Set series data
    // wrap in timeout to solve the issue of profit axis not scaling properly on tab switch back
    setTimeout(() => {
      Object.values(ChartSeriesEnum).forEach((seriesKey) => {
        series.current[seriesKey].data.setAll(data.series);
      });
      if (data.isValid === false) return;
      setTitle(getChartTitleText(data.name));
    }, 0);

    // Add export menu
    const myExporting = am5plugins_exporting.Exporting.new(root.current, {
      filePrefix: currentTargetName,
      dataSource: data.series,
    });
    myExporting.events.on("exportstarted", function () {
      // chartTitleLabel.current.show();
      // console.log(chartTitleLabel.current.hidden)
      // chartTitleLabel.current.hidden = false;
      chartTitleLabel.current.setAll({
        opacity: 1,
      });
      // chartTitleLabel.current.opacity = 1;
    });
    myExporting.events.on("exportfinished", function () {
      // chartTitleLabel.current.hide();
      // chartTitleLabel.current.hidden = true;
      // chartTitleLabel.current.opacity = 0;
      chartTitleLabel.current.setAll({
        opacity: 0,
      });
    });
    setExporting(myExporting);
  }, [data]);

  // On expansion change, make sure the target is still valid.
  // If it is not, select the closest target.
  // Break ties by picking the one higher up in the select.
  useEffect(() => {
    if (!isSortedTargetsReady) return;
    const newTargetMap = sortedTargets.reduce(
      (acc, t) => ({
        ...acc,
        [getNameWithoutDuplicates(t, mediaHierarchy).trim()]:
          getTargetWithoutDuplicates(t),
      }),
      {}
    );

    if (currentTargetName === "") {
      setCurrentErrorKey(currentTargetName);
      dispatch(
        setTargetInfo({
          targetMap: newTargetMap,
          currentTarget: Object.values(newTargetMap)?.[0] || null,
        })
      );
      return;
    }

    const newTarget =
      newTargetMap[currentTargetName] != null
        ? currentTarget
        : getFallbackTarget(currentTarget, Object.values(newTargetMap));

    dispatch(
      setTargetInfo({ targetMap: newTargetMap, currentTarget: newTarget })
    );
  }, [sortedTargets, isSortedTargetsReady]);

  return (
    <LoadingSection
      isLoading={isLoading || isUpdateLoading}
      type={
        isUpdateLoading && !isLoading ? LoadingType.Show : LoadingType.Cover
      }
      coverColor={
        isUpdateLoading && !isLoading ? "hsl(0 0% 100% / 70%)" : undefined
      }
    >
      <ChartControls
        am5Exporting={exporting}
        chartExportFormats={defaultExportFormats}
        presentationModePopOverContainer={presentationModePopOverContainer}
        isInPresentationMode={isInPresentationMode}
        enterPresentationMode={enterPresentationMode}
        exitPresentationMode={exitPresentationMode}
      >
        <FormControl>
          <Select
            value={currentTargetName}
            autoWidth
            aria-label="Medium"
            MenuProps={{
              container: presentationModePopOverContainer,
              disableScrollLock: true,
            }}
          >
            {Object.entries(targetMap).map(([n, t]) => {
              return (
                <MenuItem
                  key={n}
                  value={n}
                  onClick={() => {
                    setCurrentErrorKey(currentTargetName);
                    dispatch(setCurrentTarget(t));
                  }}
                >
                  {n}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </ChartControls>
      <Box id={CHART_ID} style={{ width: "100%", height: "100%" }}></Box>
    </LoadingSection>
  );
};

export default CurvesChart;
